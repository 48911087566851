<template>
  <AppCcc
    ref="newCccReference"
    :content="banners"
    :context="cccAppContext"
    :scene-data="channelSceneCccData"
    :cate-links="cateLinks"
    :hooks="hooks"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
export default {
  name: 'AppNewCcc',
  components: {
    AppCcc,
  },
  props: {
    appContent: {
      type: Object,
      default() {
        return {}
      }
    },
    hooks: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    ...mapState('config_index', ['context']),
    ...mapGetters('config_index', ['topTabList']),
    banners() {
      return this.appContent.content || []
    },
    channelId() {
      return this.appContent.channelId
    },
    curTab() {
      return this.topTabList?.length ? this.topTabList.find(i => i.channelId === this.channelId) : null
    },
    channelSceneCccData() {
      let isFirstChannel = false
      let channelInfo = {}
      const result = []
      if (this.appContent?.channelAbt?.posKey) result.push(this.appContent.channelAbt.posKey.slice(2))

      if (this.topTabList?.length) {
        const firstChannel = this.topTabList[0] || {}
        isFirstChannel = firstChannel.channelId === this.channelId
      }

      if(this.channelId && this.topTabList?.length) {
        channelInfo = this.topTabList?.find(tab => tab.channelId === this.channelId) ?? {}
      }

      return {
        pageType: this.appContent?.pageType || '',
        pageFrom: 'homePage',
        isFirstChannel,         // 是否是第一个频道
        channelInfo, // 当前频道信息
        poskey: result.join(','),
        sceneName: this.curTab?.channelName,
        tabName: this.curTab?.channelName,
        id: this.appContent?.id || '',
        extendData: JSON.parse(this.appContent?.extension ?? '{}') // 页面全局配置
      }
    },
    cccAppContext() {
      const { dateLangMap, SiteUID, lang, language, serverTime, WEB_CLIENT, GB_cssRight, PUBLIC_CDN, IMG_LINK, LAZY_IMG_PIXEL, LAZY_IMG, LAZY_IMG_SQUARE, langPath, clientRender, RESOURCE_SDK } = this.context
      return {
        extension: JSON.parse(this.appContent?.extension || '{}') || {},
        channelId: this.appContent.channelId,
        deliveryId: this.appContent.deliveryId,
        clientRender,
        forceRenderNumber: 8,
        WEB_CLIENT,
        SiteUID,
        lang,
        dateLangMap,
        serverTime,
        GB_cssRight,
        langPath,
        PUBLIC_CDN,
        IMG_LINK,
        LAZY_IMG_PIXEL,
        LAZY_IMG,
        LAZY_IMG_SQUARE,
        RESOURCE_SDK,
        language: {
          coupon: language.coupon,
          flashSale: language.flashSale,
          productList: language.productList,
          ranking: language.ranking,
          dailyNew: language.dailyNew,
          countdown: language.countdown,
          recomendFlow: language.recomendFlow,
          freeshipping: language.freeshipping,
          welfare: language.welfare,
          couponItem: language.couponItem,
          itemLanguage: language.itemLanguage,
          slideBanner: language.slideBanner,
          couponDynamic: language.couponDynamic,
          superSales: language.superSales,
          quarterProduct: language.quarterProduct,
          trendStoreRecommend: language.trendStoreRecommend,
          trendEntry: language.trendEntry,
        },
      }
    },
    cateLinks() {
      return this.appContent?.cateLinks
    },
  },
  methods: {
    refreshAnalysis() {
      this.$refs['newCccReference']?.resetExpose?.()
    }
  },
}
</script>
