import schttp from 'public/src/services/schttp'
// import xx from './mock.json'
// import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
// 信息流落地页点后推
export async function getRecGoodsInfo({ scene_id = 252, goodsId, productLimit = 0, jsonRuleId, triggerEvent, isClick, isAddbag, location = '', exposedGoodsId = '', atomFields, atomCccParams, isPaid = false, atomScene = {}, abtInfoV3 }, requestInstance = null) {
  let resultGoods = []
  const data = {
    scene_id: scene_id,
    location: location,
    triggerEvent: triggerEvent,
    jsonRuleId: jsonRuleId,
    limit: productLimit + 2, 
    page_enable: 0,
    // 过滤售罄
    filterSoldOutConfig: {
      on: true,
    },
    contextParams: {
      'top_goods_id': goodsId, // 商品ID (落地页未点击时传入口图，落地页有点击传点击商品）(不做置顶)
      'is_click': isClick || 0, // 用户落地页是否有点击      0:无点击  1:有点击
      'is_addbag': isAddbag || 0, // 用户落地页是否有加车   0:无  1:有
      'exposed_goods_id': exposedGoodsId // 信息流和落地页已曝光商品list,逗号分隔，推荐依据该入参做去重过滤
    },
    atomFields: {
      detailImage: 1,
      stock: true,
      prices: true,
      sheinClubPromotionInfo: true,
      newFlashPromotion: true,
      flashZonePromotion: true,
      estimatedPrice: 1,
      ...atomFields,
    },
    atomCccParams: atomCccParams,
    isPaid: isPaid,
    newPreHandle: true,
    atomScene: atomScene,
    abtInfoV3: {
      newProductCard: true,
      ...abtInfoV3,
    },
  }
  // requestInstance?.abort()
  // !requestInstance && (requestInstance = new SchttpAbortCon())
  const result = await schttp({
    method: 'POST',
    url: '/api/recommend/facadeAtom/get',
    signal: requestInstance.signal,
    data,
  })
  resultGoods = result?.info?.products?.slice(0, productLimit) || []
  // resultGoods = xx?.slice(0, productLimit) || []
  // 自加逻辑，标识是点后推商品类型
  resultGoods?.forEach(item=> {
    item.type = 'recommend-for-you'
    item.showStates = true
  })
  return resultGoods
}
