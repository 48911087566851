import FrameCycle from './cycle'
export default function (maxFramePriority) {
  return {
    mounted () {
      this.displayPriority = 0
      const frameCycle = new FrameCycle(maxFramePriority, displayPriority => {
        this.displayPriority = displayPriority
      })
      frameCycle.run()
    },
    methods: {
      defer(priority) {
        return this.displayPriority >= priority
      }
    }
  }
}
