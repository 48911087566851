var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',{staticClass:"c-suspension__ctn mshe-z-returnTop",class:{'hide': _vm.scrollHide},style:({'visibility': _vm.show ? '' : 'hidden', 'top': `${_vm.iconTop}rem` }),attrs:{"id":"j-suspension__ctn"}},[_c('div',{directives:[{name:"enterkey",rawName:"v-enterkey"}],staticClass:"c-suspension__main j-suspension__main handle",style:({
      'background-image': `url(${_vm.getTransformImg(_vm.item.imgSrc)})`,
      transform: `translateY(${_vm.iconPosition.move}px)`,
    }),attrs:{"da-event-click":"1-15-3-1","data-sa-abtinfo":_vm.sa_data,"data-aod-id":_vm.aodId,"data-href":_vm.iconCccPublic.getCccLink && _vm.iconCccPublic.getCccLink({
      item: _vm.item,
      cccCateInfo: _vm.cateInfo
    }),"data-spm":[_vm.iconCccPublic.saName, _vm.buildImgParams(0, _vm.item).minSpm].join('`'),"data-position-index":1,"data-href-tag":_vm.item.hrefTypeTag,"data-href-target":_vm.item.hrefTarget,"data-href-type":_vm.item.hrefType,"data-href-type-two":_vm.item.hrefType2,"data-scene-name":_vm.channelName,"data-operation-component-name":_vm.propData.content && _vm.propData.content.name,"data-operation-name":_vm.propData.oper_name,"tabindex":"0","role":"button","aria-label":_vm.item.ada},on:{"touchstart":_vm.handleTouchStart,"touchmove":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleTouchMove.apply(null, arguments)},"touchend":_vm.handleTouchEnd}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.closeAuth),expression:"closeAuth"},{name:"enterkey",rawName:"v-enterkey"}],staticClass:"c-suspension__close iconfont icon-close",attrs:{"tabindex":"0","role":"button","aria-label":_vm.SHEIN_KEY_PWA_15567},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.close.apply(null, arguments)}}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }