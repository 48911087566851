<template>
  <div
    v-if="item"
    id="j-suspension__ctn"
    class="c-suspension__ctn mshe-z-returnTop"
    :class="{'hide': scrollHide}"
    :style="{'visibility': show ? '' : 'hidden', 'top': `${iconTop}rem` }"
  >
    <!-- 1.1733是首页频道tab的高度 -->
    <div
      v-enterkey
      class="c-suspension__main j-suspension__main handle"
      :style="{
        'background-image': `url(${getTransformImg(item.imgSrc)})`,
        transform: `translateY(${iconPosition.move}px)`,
      }"
      da-event-click="1-15-3-1"
      :data-sa-abtinfo="sa_data"
      :data-aod-id="aodId"
      :data-href="iconCccPublic.getCccLink && iconCccPublic.getCccLink({
        item,
        cccCateInfo: cateInfo
      })"
      :data-spm="[iconCccPublic.saName, buildImgParams(0, item).minSpm].join('`')"
      :data-position-index="1"
      :data-href-tag="item.hrefTypeTag"
      :data-href-target="item.hrefTarget"
      :data-href-type="item.hrefType"
      :data-href-type-two="item.hrefType2"
      :data-scene-name="channelName"
      :data-operation-component-name="propData.content && propData.content.name"
      :data-operation-name="propData.oper_name"
      tabindex="0"
      role="button"
      :aria-label="item.ada"
      @touchstart="handleTouchStart"
      @touchmove.prevent.stop="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <i
        v-show="closeAuth"
        v-enterkey
        class="c-suspension__close iconfont icon-close"
        tabindex="0"
        role="button"
        :aria-label="SHEIN_KEY_PWA_15567"
        @click.prevent.stop="close"
      >
      </i>
    </div>
  </div>
</template>

<script>
/* globals gbFixedContainerStyle */
import common from './mixins/common'
import { CccInit, getCccCateInfo } from 'public/src/pages/common/ccc_public_v2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
import { transformImg } from '@shein/common-function'

daEventCenter.addSubscriber({ modulecode: '1-15-3' })

const { language: { SHEIN_KEY_PWA_15567 } = {}, hasPolicyBanner = false,  hasTopBanner = false } = gbCommonInfo
let timer = null

export default {
  mixins: [common],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 二级首页频道id
    secondaryHomeChannelId: {
      type: [Number, String],
      default: ''
    },
    channelName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      initIng: false,

      show: false,
      closeAuth: false,

      gbFixedContainerStyle,
      hasPolicyBanner,
      hasTopBanner,
      iconTop: 0,
      scrollHide: false,

      item: null,
      propData: {},
      cateInfo: {},

      iconCccPublic: {},
      
      iconPosition: {
        space: 10, // 可以移动的空间
        start: 0, // 鼠标按下时候的位置
        moveStartY: 0, // 上一次icon偏移的距离
        move: 0, // icon 需要偏移多少
      },

      abtInfo: {},
      SHEIN_KEY_PWA_15567
    }
  },
  computed: {
    sa_data() {
      let saAbt = []
      this.abtInfo.child && this.abtInfo.child.length && this.abtInfo.child.forEach(_ => {
        saAbt.push(`${this.abtInfo.poskey}\`${_.eid}\`${_.bid}`)
      })
      return saAbt.join(',')
    },
    aodId() {
      return this.propData?.content?.props?.style?.aod_id || '0'
    },
    curChannelId() {
      return this.secondaryHomeChannelId || this.channelId
    }
  },
  watch: {
    // gbFixedContainerStyle: {
    //   handler: function (val, oldVal) {
    //     if (this.dragdealer && this.item) {
    //       this.$nextTick(() => {
    //         this.dragdealer.reflow()
    //       })
    //     }
    //   },
    //   deep: true
    // },
    async curChannelId () {
      await this.init()
    }
  },
  async mounted () {
    this.curChannelId && await this.init()
  },
  deactivated () {
    // this.dragdealer = null
    $(window).unbind('scroll.suspension_icon')
  },
  methods: {
    getTouches(e) {
      return (
        (e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])) || {}
      )
    },
    handleTouchStart(e) {
      const { pageY } = this.getTouches(e)
      this.iconPosition.start = pageY
      this.iconPosition.moveStartY = this.iconPosition.move

      // 防止点击跟拖拽冲突
      timer = setTimeout(() => {
        this.iconCccPublic.clickLink && this.iconCccPublic.clickLink({
          item: this.item,
          cccCateInfo: this.cateInfo,
          gaIndex: 0,
          scIndex: 0,
          shouldUserPath: true
        })
      }, 200)
    },
    handleTouchMove(e) {
      // 拖拽场景不触发点击
      clearTimeout(timer)
      timer = null
      // const target = e.target
      const { pageY } = this.getTouches(e)
      // 偏移量
      const space = this.iconPosition.space
      const distance = this.iconPosition.moveStartY + pageY - this.iconPosition.start
      const move = distance < 0 ? 0 : distance > space ? space : distance
      this.iconPosition.move = move
    },
    handleTouchEnd() {
      window.sessionStorage?.setItem('suspension-pos', this.iconPosition.move)
    },
    async init () {
      if (window.sessionStorage?.getItem('suspension-close')) return
      if (this.initIng) return
      this.initIng = true
      this.calcIconTop()
      await this.refreshData()
      this.initScroll()
      this.initIng = false
      const initMoveHeight = this.calcInitMoveHeight()
      this.iconPosition.move = Number(window.sessionStorage?.getItem('suspension-pos') || initMoveHeight || 1)
    },
    calcIconTop() {
      let top = this.hasPolicyBanner ? `${+(gbFixedContainerStyle.top.replace('rem', '')) - +(gbFixedContainerStyle.policybanner.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', '')) + 1.1733}` : `${+(gbFixedContainerStyle.top.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', '')) + 1.1733}`

      if(!this.hasTopBanner) {
        const hasTopBannerHeight = Math.ceil($('.j-config-top-banner').height() || 0)
        hasTopBannerHeight !== 0 && (top = top + ((hasTopBannerHeight * 2) / 75).toFixed(2))
      }
      if(gbFixedContainerStyle.branch === '0rem') {
        top = parseFloat(top) + 2.02
      }
      this.iconTop = top
    },
    calcInitMoveHeight() {
      const htmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) ?? 0
      // 页面高度 - 顶部距离(banner+header+tablist) - bottom固定的4.5rem
      const top = this.iconTop
      const topHeight = parseFloat(top)
      const footerHeight = 4.5 // 4.5rem bottom
      const space = 20
      const remainingHeight = window.innerHeight - topHeight * htmlFontSize - footerHeight * htmlFontSize - space
      return remainingHeight.toFixed(0)
    },
    initScroll () {
      if (!this.item) return
      const $window = $(window)
      let beforeScrollTop = $window.scrollTop()
      let afterScrollTop
      let scrollDelta
      let separation = false
      let separationTimeout = null
      let stopScrollTimeout = null
      $(window).bind('scroll.suspension_icon', () => {
        if (!separation) {
          clearTimeout(separationTimeout)
          separationTimeout = setTimeout(() => {
            afterScrollTop = $window.scrollTop()
            scrollDelta = afterScrollTop - beforeScrollTop
            if (scrollDelta > 100) {
              this.scrollHide = true
            } else if (scrollDelta < -100) {
              this.scrollHide = false
            }
            beforeScrollTop = afterScrollTop
            separation = false
          }, 300)
          clearTimeout(stopScrollTimeout)
          stopScrollTimeout = setTimeout(() => {
            this.scrollHide = false
          }, 5000)
        }
        separation = true
      })
    },
    async fetchData () {
      if (!this.curChannelId) return
      const abtInfo = await getUserAbtData()
      this.abtInfo = abtInfo['CccAppIcon'] || {}

      const abtParam = encodeURIComponent(abtInfo?.CccAppIcon?.param)
      const res = await schttp({
        url: `/api/ccc/iconConfig/get?channelId=${this.curChannelId}&abtBranch=${abtParam || ''}`
      })
      this.propData = res?.data?.content?.[0]
      this.item = res?.data?.content?.[0]?.content?.props?.items?.[0]
      this.closeAuth = res?.data?.content?.[0]?.content?.props?.style?.closeAuth === '1'

      if (!this.propData || !this.item) return

      getCccCateInfo({
        cccData: [{ operations: [this.propData] || {} }],
        cccOldCateInfo: this.cateInfo
      }).then(cateRes => {
        this.cateInfo = cateRes
        this.$nextTick(() => {
          // icon 可移动的距离
          this.iconPosition.space = this.$el.clientHeight || 10
        })
      })
      this.iconCccPublic = new CccInit({
        context: res?.data,
        propData: this.propData,
        index: 1,
        navName: '',
        sceneName: this.channelName,
        tagId: this.propData.tag_id,
        abtGroups: [abtInfo],
        styleId: 0,
        vueContext: this
      })
    },
    async refreshData () {
      if (window.sessionStorage?.getItem('suspension-close')) return
      this.show = false
      await this.fetchData()
      this.iconCccPublic.preFetchCampaignsPage && this.iconCccPublic.preFetchCampaignsPage({
        item: this.item,
        cccCateInfo: this.cateInfo,
        gaIndex: 0,
        scIndex: 0,
        shouldUserPath: true
      })
      if (this.item?.imgSrc) {
        const image = new Image()
        image.src = this.getTransformImg(this.item.imgSrc)
        image.onload = () => {
          this.show = true
          daEventCenter.triggerNotice({
            daId: '1-15-3-2',
            extraData: {
              target: $('.j-suspension__main')[0],
              channelName: this.channelName,
              eventLabel: this.gaLabel,
              aodId: this.aodId,
            }
          })
        }
      }
    },
    close () {
      // 点击场景不触发跳转
      clearTimeout(timer)
      timer = null

      this.show = false
      window.sessionStorage?.setItem('suspension-close', 1)
    },
    getTransformImg(imgUrl) {
      let transformUrl = this.transformImg({ img: imgUrl })
      if (/\.(jpg|jpeg|png)$/.test(transformUrl)) {
        transformUrl =  transformUrl.replace(/\b.(jpg|jpeg|png)\b/, '.webp')
      }
      return transformUrl
    },
    transformImg,
  }
}
</script>
<style lang="less">
.c-suspension {
  &__ctn {
    position: fixed;
    right: .08rem;
    bottom: 4.5rem;
    top: 2rem;
    width: 1.65rem;
    pointer-events: none;
    transition: transform .5s, opacity .5s;
    &.hide {
      transform: translateX(54.8%);
      opacity: 0.4;
    }
  }
  &__main {
    position: absolute;
    right: 0;
    top: -1.5rem;
    width: 1.65rem;
    height: 1.65rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-align: center;
    pointer-events: all;
  }
  &__close {
    box-sizing: content-box;
    position: absolute;
    right: 0;
    top: 0;
    padding: .11rem;
    transform: translate(0.2rem, -0.2rem) scale(0.5);
    font-size: 12px;
    width: .75rem;
    height: .75rem;
    line-height: .75rem;
    border-radius: 50%;
    background: rgba(102, 102, 102, 0.6);
    background-clip: content-box;
    color: #fff;
    font-weight: bold;
  }
}
</style>
