export default class NetworkDetect {
  callbackList = []
  constructor(options = {}) {
    this.isOnce = options.isOnce
  }
  addHandler(cb) {
    this.callbackList.push(cb)
  }
  removeHandler(cb) {
    const index = this.callbackList.findIndex(cb)
    this.callbackList.splice(index, 1)
  }
  handler() {
    this.callbackList.forEach(cb => {
      cb()
    })
    if (this.isOnce) {
      this.unWatch()
    }
  }
  watch() {
    window.addEventListener('online', this.handler.bind(this))
  }
  unWatch() {
    this.callbackList = []
    window.removeEventListener('online', this.handler)
  }
}
