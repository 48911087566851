
import { mapGetters } from 'vuex'
import { transformImg } from '@shein/common-function'
import { CCC_SA_JUMP_TYPE } from 'public/src/pages/common/analysis/ccc_config_analysis.js'


/**
 * TODO : 原来存在于 app.vue provide 的公共方法，抽离到该 mixins
 */
const mixin = {
  data () {
    return {
      CCC_SA_JUMP_TYPE
    }
  },
  computed: {
    ...mapGetters('config_index', [
      'tagId',
      'aodAbtData',
      'channelId',
    ]),
    id () {
      return this.propData && this.propData.content && this.propData.content.id || ''
    },
  },
  methods: {
    /**
     * 处理图片地址
     * @param {String} img
     * @param {Boolean} no_akamai
     * @description 确保你的组件有 this.context.PUBLIC_IMG
     */
    // transformImg (img) {
    //   return transformImg({ img })
    // },
    transformImg,
    // replaceHrefSpeCha (href) {
    //   return replaceHrefSpecChar({ href: href })
    // },
    /**
     * 图片埋点参数数据
     * @param {Number} swiperIndex CarouselCols 组件独有处理，表示第几屏
     */
    buildImgParams (pitId = -1, imgData = { hrefType: 'noJump' }, swiperIndex = 0) {
      const type = imgData.hrefType === 'special' ? imgData.hrefType2 : imgData.hrefType
      const saJumpTypeID = imgData.hrefTypeId || CCC_SA_JUMP_TYPE[type] || '-'
      const minSpm = `${swiperIndex ? `${swiperIndex}_${pitId + 1}` : pitId + 1}\`0\`0\`${saJumpTypeID}\`${this.id}`
      return {
        minSpm
      }
    },
  }
}

export default mixin
