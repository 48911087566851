import _requestAnimationFrame from './polyfill'
export default class FrameCycle {
  constructor (maxFramePriority, watchFun) {
    this.displayPriority = 0
    this.list = new Array(maxFramePriority)
    this.watchFun = typeof watchFun == 'function' ? watchFun : null
  }

  run () {
    if (this.list.length > this.displayPriority) {
      this.step()
    }
  }

  step () {
    _requestAnimationFrame(() => {
      this.displayPriority++
      this.watchFun && this.watchFun(this.displayPriority)
      this.notify()
    })
  }

  notify () {
    this.list[this.displayPriority] && (this.list[this.displayPriority].forEach(cb => {
      cb(cb.__args_comp__)
    }))
    this.run()
  }

  subscribe (comp, displayPriority) {
    let cb
    const fun = new Promise(resolve => {
      cb = resolve
      cb.__args_comp__ = comp
    })
    if (this.list[displayPriority]) {
      this.list[displayPriority].push(cb)
    } else {
      this.list[displayPriority] = [cb]
    }
    return () => fun
  }
}
