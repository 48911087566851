<template>
  <div
    v-if="tabList && tabList.length && tabList.length > 1"
    class="homepage-toptab j-homepage-toptab"
  >
    <s-tab
      v-model="activeId"
      type="auto"
      :style="{ borderBottom: bgColor === 'transparent' ? 'none' : showBlock ? 'none' : '' }"
      :bg-color="bgColor"
      :selected-color="showBlock ? (hasImmersiveBanner ? '#FFF' : topTabColor || (IS_RW ? '#FC4070' : '#000')) : (IS_RW ? '#FC4070' : '#000')"
      :un-selected-color="HomePageFontStyleNew ? showBlock ? (hasImmersiveBanner ? '#FFF' : topTabColor || '#000') : '#000' : hexToRGBA(showBlock ? (hasImmersiveBanner ? '#FFF' : topTabColor || '#000') : '#000',0.66)"
      :class="{'home-page__tab' : true, 'home-page__show-icon-img': showIconImg}"
      @change="handleTabChange"
    >
      <s-tab-item
        v-for="(item, index) in tabList"
        :id="item.channelId"
        :key="index"
        v-tap="getTapAnalysis({id: '2-22-59', item, index: index + 1 })"
        v-expose="getExposeAnalysis({ id: '2-22-60', item, index: index + 1 })"
        :da-eid="item.channelId"
        class="abt-item"
      >
        <template
          v-if="item?.pictureContent?.isShow && item.selectedImageLoaded && item.unSelectedImageLoaded"
        >
          <div :style="getIconContent(item, activeId === item.channelId)">
            <transition name="home-tab__icon">
              <TransformImg
                v-if="activeId === item.channelId"
                :style="getIconStyle(item, activeId === item.channelId)"
                class="home-tab__icon"
                fit="contain"
                :show-immediately="true"
                :img-src="item.pictureContent?.selectedImage?.src"
                :alt="item.terminalShowName"
              />
            </transition>
            <transition name="home-tab__icon">
              <TransformImg
                v-if="activeId !== item.channelId"
                :style="getIconStyle(item, activeId === item.channelId)"
                class="home-tab__icon"
                fit="contain"
                :show-immediately="true"
                :img-src="item.pictureContent?.unSelectedImage?.src"
                :alt="item.terminalShowName"
              />
            </transition>
          </div>
        </template>
        <template v-else>
          {{ item.terminalShowName }}
        </template>
      </s-tab-item>
    </s-tab>
  </div>
</template>

<script>
// import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import TransformImg from 'public/src/pages/components/transformImg/index.vue'
import { Tab as STab, TabItem as STabItem } from '@shein/sui-mobile'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
const { IS_RW } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter'
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'HomeToptabNew',
  components: {
    STab,
    TransformImg,
    STabItem,
  },
  props: {
    channelId: {
      type: [String, Number],
      default: ''
    },
    tabList: {
      type: Array,
      default: () => []
    },
    topTabColor: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    bgImgSrc: {
      type: String,
      default: ''
    },
    immersiveFlag: {
      type: Boolean,
      default: false
    },
    showBlock: {
      type: Boolean,
      default: false
    },
    hasImmersiveBanner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      IS_RW,
      activeId: 0,
      showIconImg: false,
      hasRendered: false,
      HomePageFontStyleNew: false
    }
  },
  computed: {
    bgColor() {
      // 首次SSR渲染时
      if(this.hasImmersiveBanner && !this.hasRendered){
        return 'transparent'
      }
      
      if(!this.showBlock){
        return '#fff'
      }
      // 存在顶栏背景图或者沉浸式时
      if(this.bgImgSrc || this.immersiveFlag){
        return 'transparent'
        // 背景图不存在，背景色有时，则取背景色
      } else if(!this?.bgImgSrc && this?.backgroundColor) {
        return this.backgroundColor
      } else { // 否则走线上
        return '#fff'
      }
    },
  },
  watch: {
    channelId: {
      handler(val) {
        this.activeId = val
      },
      immediate: true
    }
  },
  activated() {
    daEventExpose.resetAll('homeTopTab')
  },
  mounted() {
    this.tabList.forEach((item) => {
      if (item?.pictureContent?.isShow && item.pictureContent?.selectedImage?.src && item.pictureContent?.unSelectedImage?.src) {
        const selectedImage = new Image()
        selectedImage.src = item.pictureContent.selectedImage.src
        selectedImage.onload = () => {
          item.selectedImageLoaded = true
          this.showIconImg = this.showIconImg || (item.unSelectedImageLoaded && item.selectedImageLoaded)
          this.$forceUpdate()
        }
        const unSelectedImage = new Image()
        unSelectedImage.src = item.pictureContent.unSelectedImage.src
        unSelectedImage.onload = () => {
          item.unSelectedImageLoaded = true
          this.showIconImg = this.showIconImg || (item.unSelectedImageLoaded && item.selectedImageLoaded)
          this.$forceUpdate()
        }
      }
    })
    this.$emit('home-tab-mounted')
    this.$nextTick(()=> {
      this.hasRendered = true
      // this.waitForRenderComplete()
    })
  },
  async created() {
      await this.getAbtInfo()
  },
  methods: {
    async getAbtInfo() {
      const { HomePageTerminal } = await getUserAbtData()
      const { param } = HomePageTerminal || {}
      this.HomePageFontStyleNew = param?.HomePageFontStyle  === 'new'
    },
    getTapAnalysis({ id, code, item, index }) {
      const data = {
        ...item,
        index,
      }
      return {
        id,
        code,
        data,
        prefix: 'homeTopTab'
      }
    },
    getExposeAnalysis({ id, item, index }) {
      const data = {
        ...item,
        index,
      }
      return {
        id,
        prefix: '',
        code: 'homeTopTab',
        data,
      }
    },
    // waitForRenderComplete() {
    //   document.onreadystatechange = () => {
    //     if (document.readyState === 'complete') {
    //       console.log('home tab complete')
    //       this.hasRendered = true
    //     }
    //   }
    // },
    getIconStyle(item, isActice) {
      let width = isActice ? item.pictureContent?.selectedImage?.width : item.pictureContent?.unSelectedImage?.width
      const height = 35
      const heightRatio = isActice ? item.pictureContent?.selectedImage?.height / height : item.pictureContent?.unSelectedImage?.height / height
      // 高度固定为35px，宽度等比缩放，用于适配下划线
      width = width / heightRatio
      return {
        width: width / 37.5 + 'rem',
        height: height / 37.5 + 'rem',
        display: 'block',
        position: 'absolute',
      }
    },
    // 给绝对定位的icon定位用
    getIconContent(item, isActice) {
      let width = isActice ? item.pictureContent?.selectedImage?.width : item.pictureContent?.unSelectedImage?.width
      const height = 35
      const heightRatio = isActice ? item.pictureContent?.selectedImage?.height / height : item.pictureContent?.unSelectedImage?.height / height
      // 高度固定为35px，宽度等比缩放，用于适配下划线
      width = width / heightRatio
      return {
        width: width / 37.5 + 'rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }
    },
    handleTabChange(data) {
      const result = this.tabList.find(i => i.channelId == data.val)
      /**
       * {
          "channelId": 0,
          "channelName": "",
          "terminalShowName": "",
          "tspUserId": 0,
          "url": ""
        }
       */
      if (result) {
        this.handleClickTab(result)
      }
    },
    /**
     * 点击 Tab 切换路由
    */
    handleClickTab(result) {
      if (this.channelId === result.channelId) return
      this.$emit('switch-tab', result)
    },
    hexToRGBA
  },
}
</script>

<style lang="less">
.homepage-toptab {
  position: sticky;
  top: 1.13rem;
  width: 10rem;
  height: 1.17rem;
  overflow: hidden;
  .zindex-translatez(@zindex-header + 3, translate3d(0, 0, @zindex-header+3px));

  .abt-item {
    padding: 0 0.24rem
  }
  .S-tab-item__inner {
    height: 100%;
  }
}
.home-page__show-icon-img .S-tab__roll-container .S-tab__line {
  bottom: 0.03rem;
}
// .home-tab__icon {
//   position: relative;
// }
.home-tab__icon-enter-active, .home-tab__icon-leave-active {
  transition: opacity 0.5s;
}
.home-tab__icon-enter, .home-tab__icon-leave-to {
  opacity: 0;
}

/* 添加一个反向的过渡效果 */
.reverse-home-tab__icon-enter-active, .reverse-home-tab__icon-leave-active {
  transition: opacity 0.5s;
}
.reverse-home-tab__icon-enter, .reverse-home-tab__icon-leave-to {
  opacity: 1;
}
</style>
