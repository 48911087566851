
import ClientOnly from 'vue-client-only'
import InformationFlowCcc from '../components/information-flow-ccc/Index'
import HomeToptab from '../components/HomeToptabNew.vue'
import SuspensionIcon from '../components/suspension_icon'
import FloatingIcon from '../components/floatingIcon'
import BottomSuspensionIcon from '../components/BottomSuspensionIcon'
import FooterInfo from '../../components/footer-shein-info'
// import lazyComponent from 'public/src/services/lazyComponent' // 组件懒加载

export const commonComponents = {
  ClientOnly,
  HomeToptab, // 新ccc使用
  InformationFlowCcc,
  SuspensionIcon,
  FloatingIcon, // 首页悬浮新ccc
  BottomSuspensionIcon,
  FooterInfo
}
